import React from "react";

export const CardComponent = (props) => {
  const { product, onOpen } = props;

  const handleOpen = ()=>{
    if (onOpen){
      onOpen();
    }
  }
  return (
    <article className="relative flex flex-col overflow-hidden bg-white" onClick={handleOpen}>
      <div className="aspect-square overflow-hidden">
        <img
          className="h-full w-full object-contain transition-all duration-300 group-hover:scale-125"
          src={product.image_url1}
          alt=""
        />
      </div>
      <div className="absolute top-0 m-2 rounded-full bg-white">
      </div>
      <div className="my-4 mx-auto flex w-10/12 flex-col items-start justify-between">
        <div className="mb-2 flex">
          <p className="mr-3 text-base font-semibold font-poppins">{product.name}</p>
        </div>
        <h3 className="mb-2 text-base text-gray-600">{product.brand}</h3>
      </div>
    </article>
  );
};
