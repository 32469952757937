import { Fragment } from "react";
import {
  Dialog,
} from "@material-tailwind/react";
import BlockLoading from "./BlockLoading";
 
export default function LoaderModal(props) {
 
  return (
    <Fragment>
      <Dialog size={"xxl"} open={props.isOpen} >
       <BlockLoading/>
      </Dialog>
    </Fragment>
  );
}