import { Fragment } from "react";
import { Dialog } from "@material-tailwind/react";

export default function ViewProductModal(props) {
  const { product, onClose } = props;
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <Fragment>
      <Dialog size={"xxl"} open={true}>
        <div className="mx-5">
          <div className="flex justify-end mt-5">
            <div onClick={handleClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <div className="text-xl">
            <p className="mr-3 text-base font-semibold font-poppins">{product.name}</p>
            </div>
          </div>
          <div className="flex justify-center mb-12">
          <h3 className="mb-2 text-base text-gray-600">{product.brand}</h3>
          </div>

          {product.image_url1 && (
            <div className="aspect-square">
              <img
                className="h-full w-full object-contain transition-all duration-300 group-hover:scale-125"
                src={product.image_url1}
                alt=""
              />
            </div>
          )}
          {product.image_url2 && (
            <div className="aspect-square">
              <img
                className="h-full w-full object-contain transition-all duration-300 group-hover:scale-125"
                src={product.image_url2}
                alt=""
              />
            </div>
          )}
          {product.image_url3 && (
            <div className="aspect-square">
              <img
                className="h-full w-full object-contain transition-all duration-300 group-hover:scale-125"
                src={product.image_url3}
                alt=""
              />
            </div>
          )}
        </div>
      </Dialog>
    </Fragment>
  );
}
