import React, { useState, useEffect } from "react";
import Nav from "../components/Nav";
import { Card } from "@material-tailwind/react";
import { CardComponent } from "../components/CardComponent";
import LoaderModal from "../components/LoaderModal";
import ViewProductModal from "../components/ViewProductModal";
import { ReplicaCard } from "../components/ReplicaCard";

export default function ReplicaPage() {
  const [products, setProducts] = useState([]);
  const [activeProduct, setActiveProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadProductData = () => {
    setIsLoading(true);
    fetch("https://sheetdb.io/api/v1/x3k5e2m2sgand")
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadProductData();
  }, []);

  return (
    <>
      <Nav>
        <div className="mx-auto max-w-screen-md bg-gray-300">
          <Card className="overflow-hidden w-100 h-70 rounded-none">
            <img
              alt="nature"
              className="h-[260] w-full object-cover object-center"
              src="https://i.ibb.co/PzywV7p/IMG-5018.jpg"
            />
          </Card>
        </div>
        <div className="flex flex-row py-5 my-5 justify-center align-middle border-b border-t">
          <span className="text-[20px] text-bold">Replica</span>
        </div>
        <section className="text-gray-700mb-10 mb-5">
          <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-4 sm:gap-4 lg:mt-16">
              {products.length > 0 &&
                products.map((product) => {
                  return (
                    <ReplicaCard
                      key={`index-${product.id}`}
                      product={product}
                    />
                  );
                })}
            </div>
          </div>
        </section>
        <div className="flex flex-row py-10 mb-10 justify-center align-middle mx-5 border-b border-t">
          <span className="text-base">
            A FRAGRANT DESIGNED TO MEET THE DEMAND FOR NATURALNESS
          </span>
        </div>
        {/* <ImageModal /> */}
      </Nav>
      <LoaderModal isOpen={isLoading} />
      {activeProduct !== null && (
        <ViewProductModal
          product={activeProduct}
          onClose={() => setActiveProduct(null)}
        />
      )}
    </>
  );
}
