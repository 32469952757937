import React from "react";

export const ReplicaCard = (props) => {
  const { product } = props;

  return (
    <article className="relative flex flex-row full-width items-stretch">
      <div className="aspect-square w-[50%] overflow-hidden">
        <img
          className="object-contain transition-all duration-300 group-hover:scale-125"
          src={product.image}
          alt=""
        />
      </div>
      <div className="ml-4 mx-auto flex flex-col justify-center align-middle">
        <div className="mb-1 flex">
          <p className="mr-3 text-[13px] font-bold font-poppins">{product.name}</p>
        </div>
        <h3 className="mb-1 text-[13px] text-gray">{product.sex}</h3>
        <h3 className="mb-1 text-[13px] text-gray">{product.type}</h3>
        <div className="mb-1 flex">
          <p className="mr-3 text-[13px] font-bold font-poppins">{product.price}</p>
        </div>
      </div>
    </article>
  );
};
