import React from "react";
import {
  Navbar,
} from "@material-tailwind/react";
 import logo from '../assets/thelab.png';

export default function Nav({children}) {

  return (
    <>
      <Navbar className="sticky inset-0 z-10 h-max max-w-full rounded-none px-4 py-0 lg:py-4">
        <div className="flex items-center justify-center text-blue-gray-900">
          <img
            alt="nature"
            className="h-[48px] w-[96px] object-cover object-center"
            src={logo}
          />
        </div>
      </Navbar>
      {children}
    </>
  );
}