import React, { useState, useEffect } from "react";
import Nav from "../components/Nav";
import { Card } from "@material-tailwind/react";
import { CardComponent } from "../components/CardComponent";
import LoaderModal from "../components/LoaderModal";
import ViewProductModal from "../components/ViewProductModal";

export default function HomePage() {

  const [products, setProducts] = useState([]);
  const [activeProduct, setActiveProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadProductData = () => {
    setIsLoading(true);
    fetch("https://sheetdb.io/api/v1/ng9volagash6e")
    // fetch("https://sheetdb.io/api/v1/ng9volagash6e"
    // , {
    //   method: "GET",
    //   headers: {
    //     Authorization: "Bearer mm7mjnvvxa3a1izi1y55p19gxp11tnlhw3ti83p0",
    //   },
    // })
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .finally(()=>{
        setIsLoading(false);
      })
  };

  useEffect(() => {
    loadProductData();
  }, []);

  return (
    <>
    <Nav>
      <div className="mx-auto max-w-screen-md bg-gray-300">
        <Card className="mb-12 overflow-hidden w-100 h-70 rounded-none">
          <img
            alt="nature"
            className="h-[260] w-[390] w-full object-cover object-center"
            src="https://www.creedfragrances.co.uk/cdn/shop/files/Header_a3b788d1-f114-450d-ad88-dbdfa9715730.jpg?v=1694619765&width=1946"
          />
        </Card>
      </div>
      <section className="text-gray-700 sm:py-16 lg:py-20">
        <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 gap-6 sm:grid-cols-4 sm:gap-4 lg:mt-16">
            {products.length > 0 &&
              products.map((product) => {
                return <CardComponent key={`index-${product.id}`} product={product} onOpen={()=> setActiveProduct(product)}/>;
              })}
          </div>
        </div>
      </section>
      {/* <ImageModal /> */}
    </Nav>
    <LoaderModal isOpen={isLoading}/>
    {
      activeProduct !== null && <ViewProductModal product={activeProduct} onClose={()=>setActiveProduct(null)}/>
    }
    
    </>
  );
}
